import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useDisclosure,
  VStack,
  Text,
  HStack,
  Icon,
  Divider,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { Trash2, FileLock, PhoneIcon, LockIcon } from "lucide-react";
import axios from "axios";

const DeleteAccount = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure(); // Second confirmation modal
  const [step, setStep] = useState(1); // Step 1: Mobile input, Step 2: OTP input
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [actionType, setActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMobileInvalid, setIsMobileInvalid] = useState(false);
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);
  const toast = useToast();
  const [userUid, setUserUid] = useState();

  const resetForm = () => {
    setStep(1);
    setMobile("");
    setOtp("");
    setIsMobileInvalid(false);
    setIsOtpInvalid(false);
  };

  const closeAndReset = () => {
    resetForm();
    onClose();
  };

  const validateMobile = (number) => {
    const isValid = /^[0-9]{10}$/.test(number);
    setIsMobileInvalid(!isValid);
    return isValid;
  };

  const validateOtp = (code) => {
    const isValid = /^[0-9]{6}$/.test(code);
    setIsOtpInvalid(!isValid);
    return isValid;
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    if (value.length > 10) setMobile(value.slice(0, 10));
    validateMobile(value);
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    setOtp(value);
    if (value.length > 6) setOtp(value.slice(0, 6));
    validateOtp(value);
  };

  const sendOTP = async () => {
    if (!validateMobile(mobile)) {
      toast({
        title: "Invalid Mobile Number",
        description: "Please enter a valid 10-digit mobile number.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}auth/v1/creds/verify/sendOTP`,
        {
          phonomail: mobile,
          sendType: "sms",
          countryCode: 91,
          appSignature: "sdfdgrew456ry54",
        }
      );
      setLoading(false);
      if (response.status === 200) {
        toast({
          title: "OTP sent!",
          description: `OTP has been sent to ${mobile}.`,
          status: "info",
          duration: 4000,
          isClosable: true,
        });
        setStep(2); // Move to OTP step
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error sending OTP!",
        description: error.response?.data?.message || "Failed to send OTP.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const verifyOTP = async () => {
    if (!validateOtp(otp)) {
      toast({
        title: "Invalid OTP",
        description: "Please enter a valid 6-digit OTP.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}auth/v1/creds/verify/verifyOTP`,
        {
          phonomail: mobile,
          otp: otp,
        }
      );
      setLoading(false);
      console.log("response====>", response);
      setUserUid(response?.data?.data?.uid);
      if (response.status === 200) {
        onClose();
        toast({
          title: `${actionType} successful.`,
          description: `Your account has been ${actionType.toLowerCase()}ed successfully.`,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        onConfirmOpen(); // Open the confirmation modal
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error verifying OTP!",
        description: error.response?.data?.message || "Failed to verify OTP.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleNextStep = () => {
    if (step === 1) sendOTP();
    else if (step === 2) verifyOTP();
  };

  const handleAction = (action) => {
    setActionType(action);
    resetForm();
    onOpen();
  };

  const handleConfirmation = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}auth/v1/creds/deactivate/${userUid}`
      );
      setLoading(false);
      if (response.status === 200) {
        console.log("response======>", response);
        toast({
          title: "Account Deactivated",
          description: "Your account has been successfully deactivated.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        onConfirmClose();
        onClose();
        alert("You Account Deactivated! ");
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Deactivation Failed",
        description:
          error.response?.data?.message || "Failed to deactivate account.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="100vh"
      bgGradient="linear(to-r, blue.100, purple.100)"
    >
      <Box
        bg="white"
        p={8}
        rounded="lg"
        shadow="2xl"
        maxW="lg"
        textAlign="center"
        borderWidth="1px"
        borderColor="gray.200"
        transition="all 0.2s"
      >
        <Text fontSize="xl" fontWeight="bold" color="gray.700">
          Deactivating or deleting your iVerse account
        </Text>
        <Text fontSize="sm" mb={6} color="gray.700">
          If you want to take a break from iVerse, you can temporarily
          deactivate this account. If you want to permanently delete your
          account, let us know.
        </Text>
        <VStack bg="#F6F6F6" rounded="12px" p={4}>
          <Box
            textAlign="start"
            cursor="pointer"
            _hover={{ transform: "scale(1.005)", shadow: "sm" }}
            onClick={() => handleAction("Deactivate")}
          >
            <Text fontWeight="600">Deactivate account</Text>
            <Text fontSize="12px">
              Your account and main profile will be deactivated and your name
              and photos will be removed from most things that you've shared.
            </Text>
          </Box>
          <Divider />
          <Box
            textAlign="start"
            cursor="pointer"
            _hover={{ transform: "scale(1.005)", shadow: "sm" }}
            onClick={() => handleAction("Delete")}
          >
            <Text fontWeight="600">Delete account</Text>
            <Text fontSize="12px">
              When you delete your iVerse account, you won't be able to retrieve
              the content or information that you've shared on iVerse.
            </Text>
          </Box>
        </VStack>
      </Box>

      {/* Modal */}
      <Modal
        isOpen={isOpen}
        onClose={closeAndReset}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p={4} bgGradient="linear(to-b, blue.50, white)">
          <ModalHeader>
            <HStack>
              {actionType === "Delete" ? (
                <Icon as={Trash2} color="red.500" />
              ) : (
                <Icon as={FileLock} color="blue.500" />
              )}
              <Text>{actionType} Account</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {step === 1 && (
              <FormControl isInvalid={isMobileInvalid}>
                <FormLabel display="flex" gap={2} alignItems="center">
                  <PhoneIcon size="16px" mr={2} color="#666666" /> Mobile Number
                </FormLabel>
                <Input
                  value={mobile}
                  onChange={handleMobileChange}
                  type="number"
                  placeholder="Enter mobile number"
                  maxLength={10}
                  borderColor="gray.300"
                />
                {isMobileInvalid && (
                  <FormErrorMessage>
                    Mobile number must be 10 digits.
                  </FormErrorMessage>
                )}
              </FormControl>
            )}

            {step === 2 && (
              <FormControl isInvalid={isOtpInvalid}>
                <FormLabel display="flex" gap={2} alignItems="center">
                  <LockIcon size="16px" mr={2} color="#666666" /> OTP
                </FormLabel>
                <Input
                  value={otp}
                  onChange={handleOtpChange}
                  type="number"
                  placeholder="Enter OTP"
                  maxLength={6}
                  borderColor="gray.300"
                />
                {isOtpInvalid && (
                  <FormErrorMessage>OTP must be 6 digits.</FormErrorMessage>
                )}
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={actionType === "Delete" ? "red" : "blue"}
              isLoading={loading}
              loadingText="Processing"
              onClick={handleNextStep}
              disabled={loading}
            >
              {step === 1 ? "Send OTP" : "Verify OTP"}
            </Button>
            <Button
              variant="outline"
              ml={3}
              onClick={closeAndReset}
              isDisabled={loading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        isCentered
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p={4} bg="white">
          <ModalHeader>Account Deactivation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure for deactivated your account?</Text>
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={onConfirmClose}>Cancel</Button>
            <Button colorScheme="blue" onClick={handleConfirmation}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeleteAccount;
